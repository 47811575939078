import React from "react";
import DashboardHeader from '../../components/admincomponents/DashboardHeader';
import AddNewVenue from '../../components/admincomponents/AddVenue';

function AddVenue({onLogout}) {
  return (
    <>
      <DashboardHeader onLogout={onLogout}/>
      <AddNewVenue />            
    </>
  );
}

export default AddVenue;
