import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import config from '../../../src/config';

function AdminDashboard() {
    const [venues, setVenues] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchVenues = async () => {
            try {
                const response = await axios.get(`${config.BASE_URL}/api/admin/get-venues`, { withCredentials: true });
                setVenues(response.data); // Set the fetched projects to state
            } catch (err) {
                setError('Failed to fetch venues');
            } finally {
                setLoading(false);
            }
        };

        fetchVenues();
    }, []);

    return (
        <>
            <div className="dashboard">
                <div className="container">
                    <h1 className="dash_title">Dashboard</h1>
                    <div className="main-section_dashboard">
                        <div class="content">
                            <div class="cards">
                                <div class="card red">
                                    <i class="fas fa-folder-open">
                                    </i>
                                    <div class="number">
                                        {venues.length}
                                    </div>
                                    <div class="label">
                                        Total Venues
                                    </div>
                                </div>
                            </div>
                            <div class="user-info">
                                {venues.map((venue, index) => (
                                    <div className="details" key={venue._id}>
                                        <div className="name">
                                            {venue.title}
                                        </div>
                                        <div className="tweet">
                                            {venue.brandDirection}
                                        </div>
                                    </div>
                                ))}
                                <i class="fab fa-twitter">
                                </i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminDashboard;
