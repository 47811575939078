import React from "react";
import DashboardHeader from '../../components/admincomponents/DashboardHeader';
import UpdateSettings from '../../components/admincomponents/UpdateSettings';

function UpdateEvent({onLogout}) {
  return (
    <>
      <DashboardHeader onLogout={onLogout}/>
      <UpdateSettings />            
    </>
  );
}

export default UpdateEvent;
