import React, { useEffect, useState } from "react";
import axios from "axios"; 
import { useParams, useNavigate, Link } from "react-router-dom";
import config from '../../config';

function VenueDetails() {
    const { id } = useParams(); // Get the project ID from the URL
    const [venue, setVenue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const fetchEventDetails = async () => {
        try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-venue/${id}`, { withCredentials: true });
            setVenue(response.data);
            
        } catch (err) {
            setError('Failed to fetch location details');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEventDetails();
    }, [id]);

    const handleDelete = async () => {
        try {
            await axios.delete(`${config.BASE_URL}/api/admin/delete-venue/${id}`, { withCredentials: true });
            // Navigate back to the project list or show a success message
            navigate('/admin/venues'); 
        } catch (err) {
            setError('Failed to delete the venue');
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    console.log("venue: ",venue);

    return (
        <div className="dashboard project_detail_wrap">
            <div className="container">
                <h1 className="dash_title">Dashboard</h1>
                <div className="back_btn_wrap">
                <button className="back-btn" onClick={() => navigate(-1)}>Go Back</button>
                </div>
                <div className="main-section">
                    <div className="projects">
                        <div className="projects-inner">
                            <div className="detail_item">
                                <h2>Venue Title</h2>
                                <div className="detail_item_inner">
                                    <p>{venue.title}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Description</h2>
                                <div className="detail_item_inner">
                                    <p>{venue.description}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>City</h2>
                                <div className="detail_item_inner">
                                    <p>{venue.city}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>State</h2>
                                <div className="detail_item_inner">
                                    <p>{venue.state}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Country</h2>
                                <div className="detail_item_inner">
                                    <p>{venue.country}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        {venue.featuredImage ? ( // No .length because it's a string
                                            <div className="detail_img_item">
                                                <h2>Featured Image</h2>
                                                <div className="banner_img_wrap">
                                                <img src={`${config.BASE_URL}${venue.featuredImage}`} alt="Featured" />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <h2>Featured Image</h2>
                                                <p>No image available.</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="detail_item">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        {venue.bannerImage ? ( // No .length because it's a string
                                            <div className="detail_img_item">
                                                <h2>Banner Image</h2>
                                                <div className="banner_img_wrap">
                                                <img src={`${config.BASE_URL}${venue.bannerImage}`} alt="Featured" />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <h2>Banner Image</h2>
                                                <p>No image available.</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h3>Center Content</h3>
                            </div>

                            <div className="detail_item">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        {venue.centerContentImage ? ( // No .length because it's a string
                                            <div className="detail_img_item">
                                                <h2>Image</h2>
                                                <div className="banner_img_wrap">
                                                <img src={`${config.BASE_URL}${venue.centerContentImage}`} alt="Featured" />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <h2>Image</h2>
                                                <p>No image available.</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Top Text</h2>
                                <div className="detail_item_inner">
                                    <p>{venue?.centerContent && JSON.parse(venue?.centerContent)?.topText}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Left Text</h2>
                                <div className="detail_item_inner">
                                    <p>{venue?.centerContent && JSON.parse(venue?.centerContent)?.leftText}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Right Text</h2>
                                <div className="detail_item_inner">
                                    <p>{venue?.centerContent && JSON.parse(venue?.centerContent)?.rightText}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Best For</h2>
                                <div className="detail_item_inner">
                                    <p>{venue.bestFor}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Best Season</h2>
                                <div className="detail_item_inner">
                                    <p>{venue.bestSeason}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h3>Event Spaces</h3>
                                <h4>Banner Section</h4>
                            </div>

                            <div className="detail_item">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        {venue.eventSpaceBannerImage ? ( // No .length because it's a string
                                            <div className="detail_img_item">
                                                <h2>Banner Image</h2>
                                                <div className="banner_img_wrap">
                                                <img src={`${config.BASE_URL}${venue.eventSpaceBannerImage}`} alt="Featured" />
                                                </div>
                                            </div>
                                        ) : (
                                            <p>No image available.</p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Banner Title</h2>
                                <div className="detail_item_inner">
                                    <p>{venue.eventSpaceBannerTitle}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Banner Description</h2>
                                <div className="detail_item_inner">
                                    <p>{venue.eventSpaceBannerDesc}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h4>Two Images Content</h4>
                            </div>

                            <div className="detail_item">
                                <h2>Title</h2>
                                <div className="detail_item_inner">
                                    <p>{venue.eventSpaceTitle1}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        {venue.eventSpaceLeftImage ? ( // No .length because it's a string
                                            <div className="detail_img_item">
                                                <h2>Left Image</h2>
                                                <div className="banner_img_wrap">
                                                    <img src={`${config.BASE_URL}${venue.eventSpaceLeftImage}`} alt="Featured" />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <h2>Left Image</h2>
                                                <p>No image available.</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="detail_item">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        {venue.eventSpaceRightImage ? ( // No .length because it's a string
                                            <div className="detail_img_item">
                                                <h2>Right Image</h2>
                                                <div className="banner_img_wrap">
                                                <img src={`${config.BASE_URL}${venue.eventSpaceRightImage}`} alt="Featured" />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <h2>Right Image</h2>
                                                <p>No image available.</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            
                            <div className="detail_item">
                                <h2>Description</h2>
                                <div className="detail_item_inner">
                                    <p>{venue.eventSpaceDesc1}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h4>Single Image Content</h4>
                            </div>

                            <div className="detail_item">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        {venue.eventSpaceCenterImage ? ( // No .length because it's a string
                                            <div className="detail_img_item">
                                                <h2>Center Image</h2>
                                                <div className="banner_img_wrap">
                                                <img src={`${config.BASE_URL}${venue.eventSpaceCenterImage}`} alt="Featured" />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <h2>Center Image</h2>
                                                <p>No image available.</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Title</h2>
                                <div className="detail_item_inner">
                                    <p>{venue.eventSpaceTitle2}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Description</h2>
                                <div className="detail_item_inner">
                                    <p>{venue.eventSpaceDesc2}</p>
                                </div>
                            </div>
                            
                            <div className="detail_item">
                                <h4>Accomodation</h4>
                            </div>

                            <div className="detail_item">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        {venue.accomodationImage ? ( // No .length because it's a string
                                            <div className="detail_img_item">
                                                <h2>Accomodation Image</h2>
                                                <div className="banner_img_wrap">
                                                <img src={`${config.BASE_URL}${venue.accomodationImage}`} alt="Featured" />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <h2>Accomodation Image</h2>
                                                <p>No image available.</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Description</h2>
                                <div className="detail_item_inner">
                                    <p>{venue.accomodationDescription}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h4>Near By</h4>
                            </div>

                            <div className="detail_item">
                                <h2>Address</h2>
                                <div className="detail_item_inner">
                                    <p>{venue.nearByAddress}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        {venue.nearByMapImage ? (
                                            <div className="detail_img_item">
                                                <h2>Map Image</h2>
                                                <div className="banner_img_wrap">
                                                <img src={`${config.BASE_URL}${venue.nearByMapImage}`} alt="Featured" />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <h2>Map Image</h2>
                                                <p>No image available.</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                           
                            <div className="detail_project_btn_wrap">
                                <div className="detail_edit_del">
                                    <div className="btn_item">
                                        <button className="detail_btns" onClick={handleDelete}>Delete</button>
                                    </div>
                                    <div className="btn_item">
                                        <Link to={`/admin/update-venue/${venue._id}`} className="update_detail detail_btns">Edit</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VenueDetails;
