import React, { useState } from "react";
import { Link } from "react-router-dom";
import footerLogo from "../assets/images/venue-icon.svg";

const Footer = () => { 

  return (    
    <footer className="site-footer d-grid color-light">
      <div className="footer-left bg-cover d-flex justify-content-center align-items-center h-100">Start Planning</div>      
      <div className="footer-right d-flex align-items-center h-100 position-relative">
        <div className="footer-right-wrapper">
          <div className="footer-widget nav-widget">
            <h6>Contact us</h6>
            <ul className="list-style-none">
              <li><Link to="#">start</Link></li>
            </ul>
          </div>
          <div className="footer-widget nav-widget">
            <h6>Request A Quote</h6>
            <ul className="list-style-none">
              <li><Link to="#">start</Link></li>
            </ul>
          </div>
        </div>
        <div className="footer-logo position-absolute"><img className="w-100" width="" height="" src={footerLogo} alt="" /></div>
      </div>      
    </footer>
  );
};

export default Footer;