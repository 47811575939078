import React from "react";
import DashboardHeader from '../../components/admincomponents/DashboardHeader';
import UpdateVenueDetail from '../../components/admincomponents/UpdateVenueDetail';

function UpdateVenue({onLogout}) {
  return (
    <>
      <DashboardHeader onLogout={onLogout}/>
      <UpdateVenueDetail />            
    </>
  );
}

export default UpdateVenue;
