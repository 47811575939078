import React from "react";
import DashboardHeader from '../../components/admincomponents/DashboardHeader';
import VenuesList from '../../components/admincomponents/VenuesList';

function AdminVenues({onLogout}) {
  return (
    <>
      <DashboardHeader onLogout={onLogout}/>
      <VenuesList  />            
    </>
  );
}

export default AdminVenues;
