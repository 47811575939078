import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import { Dropdown } from 'primereact/dropdown';

function AddEvent() {
  const navigate = useNavigate();
  const [venues, setVenues] = useState([]);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    venueId: '',
    centerContent: {},
    featuredImage: null,
    bannerImage: null,
    centerContentImage: null,
  });

  const [itineraryContent, setItineraryContent] = useState([
    { title: '', description: '', itineraryImage: null }
  ]);

  const handleItineraryChange = (index, event) => {
    const newFields = itineraryContent.map((field, fieldIndex) => {
      if (index === fieldIndex) {
        return { ...field, [event.target.name]: event.target.value };
      }
      return field;
    });
    setItineraryContent(newFields);
  };

  const handleItineraryImageChange = (index, event) => {
    const image = event.target.files;
    const newFields = itineraryContent.map((field, fieldIndex) => {
      if (index === fieldIndex) {
        return { ...field, [event.target.name]: image[0] };
      }
      return field;
    });
    setItineraryContent(newFields);
  };

  const addItineraryFields = () => {
    setItineraryContent([...itineraryContent, { title: '', description: '', itineraryImage: null }]);
  };

  const removeItineraryFields = (index) => {
    const newFields = itineraryContent.filter((field, fieldIndex) => index !== fieldIndex);
    setItineraryContent(newFields);
  };

  const fetchVenues = async () => {
    try {
        const response = await axios.get(`${config.BASE_URL}/api/admin/get-venues`, { withCredentials: true });
        
        if(response.data){
            setVenues(response.data);
        }

    } catch (err) {
        console.log('Failed to fetch venues');
    }
  };

  useEffect(() => {
      fetchVenues();
  }, []);

  const handleInputChange = (e) => {

    console.log("e: ", e);
    const { name, value, files } = e;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files : value // Set files if they exist, otherwise set value
    }));
  };

  const handleImageUpload = async (image) => {
    const formData = new FormData();
    formData.append('image', image);

    try {
      const response = await axios.post(`${config.BASE_URL}/api/admin/upload-event-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data?.image; // Assuming your API returns the image URL
    } catch (error) {
      console.error('Image upload failed:', error);
      return null;
    }
  };

  const handleAddLocation = async (e) => {
    e.preventDefault();

    var updatedItinerary = [];
    if(itineraryContent.length > 0) {
      await Promise.all(itineraryContent.map(async (item, index) => {
        if(item?.itineraryImage){
          const imageData = await handleImageUpload(item.itineraryImage);
          updatedItinerary.push({title: item.title, description: item.description, itineraryImage: imageData});
        }
      }));
    }

    const data = new FormData();
    data.append('title', formData.title);
    data.append('venueId', formData.venueId?._id);
    data.append('centerContent', JSON.stringify({topText: formData.topText, leftText: formData.leftText, rightText: formData.rightText}));
    data.append('itineraryContent', JSON.stringify(updatedItinerary));
    
    // // Single file upload for featured image
    if (formData.featuredImage) {
      data.append('featuredImage', formData.featuredImage[0]);
    }

    if (formData.bannerImage) {
      data.append('bannerImage', formData.bannerImage[0]);
    }

    if (formData.featuredImage) {
      data.append('centerContentImage', formData.centerContentImage[0]);
    }
    
    try {
      const response = await axios.post(`${config.BASE_URL}/api/admin/add-event`, data, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        const token = localStorage.getItem('token');
        if (token) {
          navigate('/admin/events');
        } else {
          navigate('/admin/login');
        }
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to add Event');
    }
  };

  console.log("formData: ", formData);

  return (
    <div className="dashboard">
      <div className="container">
        <h1 className="dash_title">Dashboard</h1>
        <div className="main-section">
        <div className="back_btn_wrap">
          <button className="back-btn" onClick={() => navigate(-1)}>Go Back</button>
        </div>
          <div className="main-inner add_project_main">
            <form onSubmit={handleAddLocation} encType="multipart/form-data">
              <div className="form-login">
                <div className="add_field titleField">
                  <h2>Event Title</h2>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={(e) => handleInputChange(e.target)}
                    required
                  />
                </div>

                <div className="add_field textareaField">
                  <h2>Select Venue</h2>
                  <Dropdown 
                    value={formData.venueId} 
                    onChange={(e) => setFormData({...formData, "venueId": e.value})} 
                    options={venues} 
                    optionLabel="title" 
                    placeholder="Select Venue" 
                    className="w-full md:w-14rem" 
                  />
                </div>

                <div className="add_field">
                  <h2>Featured Image</h2>
                  <div className="form-group">
                    <div className="file-upload">
                      <input
                        type="file"
                        className="form-control"
                        name="featuredImage"
                        onChange={(e) => handleInputChange(e.target)}
                        required
                      />
                      <i className="fa fa-camera i-pic-upload"></i>
                    </div>
                  </div>
                </div>

                <div className="add_field">
                  <h2>Banner Image</h2>
                  <div className="form-group">
                    <div className="file-upload">
                      <input
                        type="file"
                        className="form-control"
                        name="bannerImage"
                        onChange={(e) => handleInputChange(e.target)}
                        required
                      />
                      <i className="fa fa-camera i-pic-upload"></i>
                    </div>
                  </div>
                </div>

                <div className="add_field textareaField">  
                  <h1>Center Content</h1>
                  <div className="contentFields">
                    <div className="add_field">
                      <h2>Image</h2>
                      <div className="form-group">
                        <div className="file-upload">
                          <input
                            type="file"
                            className="form-control"
                            name="centerContentImage"
                            onChange={(e) => handleInputChange(e.target)}
                            required
                          />
                          <i className="fa fa-camera i-pic-upload"></i>
                        </div>
                      </div>
                    </div>

                    <div className="add_field titleField">
                      <h2>Top Text</h2>
                      <input
                        type="text"
                        name="topText"
                        value={formData.topText}
                        onChange={(e) => handleInputChange(e.target)}
                        required
                      />
                    </div>

                    <div className="add_field titleField">
                      <h2>Left Text</h2>
                      <input
                        type="text"
                        name="leftText"
                        value={formData.leftText}
                        onChange={(e) => handleInputChange(e.target)}
                        required
                      />
                    </div>

                    <div className="add_field titleField">
                      <h2>Right Text</h2>
                      <input
                        type="text"
                        name="rightText"
                        value={formData.rightText}
                        onChange={(e) => handleInputChange(e.target)}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="add_field itineraryFields">
                  <h1>Add Itinerary</h1>
                    {itineraryContent.map((field, index) => (
                      <div key={index} className="addItineraryFields">
                        <div className="add_field">
                          <h2>Title</h2>
                          <input
                            name="title"
                            type="text"
                            value={field.title}
                            placeholder="Title"
                            onChange={(event) => handleItineraryChange(index, event)}
                          />
                        </div>
                        
                        <div className="add_field">
                          <h2>Description</h2>
                          <input
                            name="description"
                            type="text"
                            placeholder="Description"
                            value={field.description}
                            onChange={(event) => handleItineraryChange(index, event)}
                          />
                        </div>

                        <div className="add_field">
                          <h2>Image</h2>
                          <div className="form-group">
                            <div className="file-upload">
                              <input
                                type="file"
                                className="form-control"
                                name="itineraryImage"
                                onChange={(event) => handleItineraryImageChange(index, event)}
                                required
                              />
                              <i className="fa fa-camera i-pic-upload"></i>
                            </div>
                          </div>
                        </div>

                        <button type="button" onClick={() => removeItineraryFields(index)}>
                          Remove
                        </button>
                      </div>
                    ))}

                    <button type="button" onClick={addItineraryFields}>Add More Itinerary</button>
                </div>
              </div>
              <div className="add_project">
                <button type="submit">Add Event</button>
              </div>
            </form>
            {error && <p className="error-message">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEvent;
