import React, { useState } from "react";
import { Link } from "react-router-dom";
import Layout from "./Layout";
import venuelistImg1 from "../assets/images/venue-list-img1.jpg";
import venuelistImg2 from "../assets/images/venue-list-img2.jpg";
import eventlistImg3 from "../assets/images/event-list-img3.jpg";
import venueIcon from "../assets/images/venue-icon.svg";
import whiskey from "../assets/videos/whiskey.mp4";
import venue from "../assets/images/venue-icon.svg";

const EventIndex = () => {    

  return (  
    <Layout>  
    <>
        <div class="event-index template-index pt-220">            
            <div className="title-block center pb-40 m-pb-10">
                <div className="container">
                    <h6>Recent</h6>
                    <h1>Events</h1>
                </div>
            </div>
            <div className="title-with-line"></div>
            <div className="events-section pt-100 pb-50 m-pt-50 m-pb-0">
                <div className="container">
                    <div className="event-listing py-100">
                        <div className="grid-item event-item hero-section container-hero-section d-flex justify-content-center color-light position-relative">
                            <div className="hero-banner">
                                <img width="" height="" src={venuelistImg1} alt="" />
                            </div>
                            <div className="hero-summary container-summary center position-absolute">
                                <div className="container-summary-wrapper m-auto w-100">                                    
                                    <div className="title-block">A 3-day Wedding Weekend</div>
                                    <div className="divider position-relative">at</div>
                                    <div className="summary-wrapper">
                                        <div className="logo-block"><img width="" height="" src={venueIcon} alt="" /></div>
                                        <div className="title-block location">Kinross</div>                                    
                                        <div className="cursive-title-block organizer">loch leven</div>
                                    </div>
                                </div>
                            </div>
                            <div className="text text-left cursive-title-block position-absolute">the architectural <span>jewel</span> in Scotland’s crown</div>
                        </div>
                        <div className="grid-item event-item hero-section container-hero-section d-flex justify-content-center center color-light position-relative">
                            <div className="hero-banner">
                                <img width="" height="" src={venuelistImg2} alt="" />
                            </div>
                            <div className="hero-summary container-summary position-absolute">
                                <div className="container-summary-wrapper m-auto w-100">                                    
                                    <div className="title-block">old course hotel</div>
                                    <div className="divider position-relative">at</div>                                    
                                    <div className="summary-wrapper">
                                        <div className="logo-block"><img width="" height="" src={venueIcon} alt="" /></div>                                    
                                        <div className="title-block location">FIFE</div>                                    
                                        <div className="cursive-title-block organizer">St. Andrews</div>
                                    </div>
                                </div>
                            </div>
                            <div className="text text-right cursive-title-block color-dark position-absolute">golf history</div>
                        </div>
                    </div>                    
                </div>
            </div>
            <div className="event-hero-section video-section full-screen hero-section d-flex justify-content-center bottom-30 bottom-overlay-dark position-relative">
                <div className="hero-banner">
                    <video loop={true} autoPlay={true} muted={true} playsInline={true}><source src={whiskey} type="video/mp4" /></video>
                </div>
                <div className="hero-summary d-flex flex-column justify-content-end align-items-center center color-light position-absolute">                
                    <div className="top-summary color-light position-absolute">
                        <div className="logo-block"><img width="" height="" src={venue} alt="" /></div>     
                        <div className="title-block"><h5>Kinross House</h5></div>
                    </div>
                    <div className="title-block"><h2>Whiskey Tour Throughout Kinross</h2></div>                
                </div>                
            </div>
            <div className="events-section pt-180 pb-30 m-pt-50 m-pb-0">
                <div className="container">
                    <div className="event-listing">
                        <div className="grid-item event-item hero-section container-hero-section d-flex justify-content-center color-light position-relative">
                            <div className="hero-banner">
                                <img width="" height="" src={eventlistImg3} alt="" />
                            </div>
                            <div className="hero-summary container-summary center position-absolute">
                                <div className="container-summary-wrapper m-auto w-100">
                                    
                                    <div className="title-block">Kinross House</div>                                    
                                    <div className="divider position-relative">at</div>
                                    <div className="summary-block">
                                        <div className="logo-block"><img width="" height="" src={venueIcon} alt="" /></div>
                                        <div className="title-block location">Kinross</div>
                                        <div className="cursive-title-block organizer">loch leven</div>                                    
                                    </div>
                                </div>
                            </div>
                            <div className="text text-left cursive-title-block position-absolute">the architectural <span>jewel</span> in Scotland’s crown</div>
                        </div> 
                    </div>                            
                </div>
            </div>
        </div>  
        
    </>
    </Layout>
  );
};

export default EventIndex;