import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";
import heroImage from "../assets/images/venue-hero-banner.jpg";
import hostingKinross from "../assets/images/hosting-at-kinross.jpg";
import eventSmallimg from "../assets/images/event-small-image-2.jpg";
import eventLargeimg from "../assets/images/event-large-image.jpg";
import architectural from "../assets/images/architectural.jpg";
import accomodation from "../assets/images/accomodation.jpg";
import nearby from "../assets/images/near-by.png";
import nearbyImg1 from "../assets/images/nearby-img1.jpg";
import nearbyImg2 from "../assets/images/nearby-img2.jpg";
import nearbyImg3 from "../assets/images/nearby-img3.jpg";
import traditional from "../assets/images/traditional.jpg";
import traditional_mobile from "../assets/images/traditional-mobile.jpg";
import venueIcon from "../assets/images/venue-icon.svg";
import { Swiper, SwiperSlide } from 'swiper/react';

const SingleVenue = () => { 

  return (
    <Layout extraClass={"single-venue"}>

       
            <div className="hero-section container-hero-section d-flex justify-content-center top-bottom-overlay position-relative">
                <div className="hero-banner">
                    <img width="" height="" src={heroImage} alt="" />
                </div>
                <div className="hero-summary container-summary position-absolute center color-light">
                  <div className="container-summary-wrapper m-auto w-100">
                  <div className="logo-block top position-absolute"><img width="" height="" src={venueIcon} alt="" /></div>
                    <div className="title-block"><h5>Kinross House</h5></div>
                    <div className="organizer-block flawlush-font">loch leven</div>
                    <div className="divider m-auto"></div>
                    <div className="location-block"><h6>Kinross</h6></div>
                    <div className="text-block">a historic 17th-century Scottish country House set against the magnificent backdrop of Loch Leven.</div>
                  </div>
                </div>
              </div>
            
           
            <div className="image-overlap-sidetext-section py-180 m-pt-120 m-pb-100">
              <div className="container">
                <div className="container-500 m-auto">
                  <div className="title-block center m-auto pb-50">
                    <h5>An Architectural Jewel Of Scotland</h5>
                  </div>
                 
                  <div className="image-block position-relative">
                    <img width="" height="" src={architectural} alt="" />
                    <div className="text text-left cursive-title-block position-absolute">the architectural jewel in Scotland’s crown</div>
                    <div className="text text-right cursive-title-block position-absolute">a traditional private country house</div>                                        
                  </div>
                  <div className="bottom-text center d-flex justify-content-center m-auto w-100 pt-60">
                      <div className="text-item">
                        <div className="title-block">Best For</div>
                        <div className="text-block">any multi-day event</div>
                      </div>
                      <div className="text-item">
                        <div className="title-block">Best Season</div>
                        <div className="text-block">autumn and spring</div>
                      </div>                      
                    </div>
                </div>
              </div>
            </div>

            <div className="title-with-line">
              <div className="container">
                <div className="title-with-line-wrapper"><h3 className="cursive-title-block">details</h3></div>
                </div>
            </div>

            <div className="tab-section-scroll">
              <ul className="list-style-none d-flex justify-content-center align-items-end">
                <li><a href="#event-spaces" className="active">Event Spaces</a></li>
                <li><a href="#accomodation">Accomodation</a></li>
                <li><a href="#near-by">Neary-by</a></li>
              </ul>
            </div>
            
            <div className="hero-section full-screen d-flex justify-content-center bottom-100 bottom-overlay-dark position-relative">
              <div className="hero-banner">
                <img width="" height="" src={hostingKinross} alt="" />
              </div>
              <div className="hero-summary center color-light position-absolute">
                <div className="title-block"><h2 className="mb-0">Hosting At Kinross</h2></div>
                <div className="text-block">a historic 17th-century Scottish country House set against the magnificent backdrop of Loch Leven.</div>
              </div>
            </div>

            <div className="image-column-section bg-black color-light center py-100 m-pt-50">
                <div className="container m-px-0">
                    <div className="grid-row d-grid">
                        <div className="grid-item grid-small center position-relative">
                            <img width="" height="" src={eventSmallimg} alt="" />
                            <div className="text text-right position-absolute">
                                <div className="cursive-title-block text-no-wrap">a cozy estate dinner</div>
                            </div>
                        </div>
                        <div className="grid-item grid-large position-relative">
                            <img width="" height="" src={eventLargeimg} alt="" />
                        </div>
                    </div>                    
                </div>
            </div>

            <div className="richtext color-light center bg-black pb-100 m-pb-70">
              <div className="container">
                <div className="richtext-block m-auto pb-80 w-100">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                  </div>
                <div className="image-block">
                  <picture>
                      <source media="(max-width:767px)" srcset={traditional_mobile} /> 
                      <img width="" height="" src={traditional} alt="" />
                  </picture>          
                </div>
                <div className="richtext-block m-auto pt-100 m-pt-70 w-100">
                  <h4 className="cursive-title-block m-pb-30">traditional details</h4>
                  <p>orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p></div>
              </div>
            </div>

            <div id="accomodation" className="hero-section accomodation-hero-section full-screen d-flex justify-content-center align-items-center position-relative">
              <div className="hero-banner">
                <img width="" height="" src={accomodation}  alt="" />
              </div>
              <div className="hero-summary color-light position-absolute">
                  <div className="title-block pb-30"><h2 className="mb-0">Accomodation</h2></div>
                  <div className="title-block"><h3 className="cursive-title-block">modern production in traditional spaces</h3></div>
              </div>
            </div>

            <div className="location-section py-150 m-py-80">
              <div className="container m-px-0">
                <div className="d-flex justify-content-center">
                  <div className="location-wrapper w-100">
                    <div className="title-block pb-40"><h2>Nearby Kinross House</h2></div>
                      <img width="" height="" src={nearby} alt="" />
                  </div>
                </div>                
                <div className="location-items center pt-150 m-pt-100">
                  <div className="grid-row">
                      <Swiper
                        spaceBetween={20}
                        loop={true}
                        centeredSlides={true}              
                        initialSlide={2}
                        slidesPerView={1.4}                        
                        breakpoints={{                                                
                        993:{   
                        loop: false,                                  
                        centeredSlides: false,                 
                        slidesPerView: 3,
                        spaceBetween: 30,
                        },
                        }}
                        >

                      <SwiperSlide>
                          <div className="grid-item">
                            <div className="image-block"><img width="" height="" src={nearbyImg1} alt="" /></div>
                            <div className="title-block">Loch Leven Castle</div>
                            <div className="text-block">autumn and spring</div>
                          </div>
                      </SwiperSlide>    
                      <SwiperSlide>
                          <div className="grid-item">
                            <div className="image-block"><img width="" height="" src={nearbyImg2} alt="" /></div>
                            <div className="title-block">Montgomery Course</div>
                            <div className="text-block">autumn and spring</div>
                          </div>
                      </SwiperSlide>    
                      <SwiperSlide>
                          <div className="grid-item">
                            <div className="image-block pb-30"><img width="" height="" src={nearbyImg3} alt="" /></div>
                            <div className="title-block">Loch Leven</div>
                            <div className="text-block">autumn and spring</div>
                          </div>
                      </SwiperSlide>
                    </Swiper>
                   
                </div>
              </div>
            </div>
            </div>           
      
        </Layout>
  );
  };

export default SingleVenue;