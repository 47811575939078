import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "../../config";

function AddEvent() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    city: '',
    state: '',
    country: '',
    centerContent: '',
    bestFor: '',
    bestSeason: '',
    eventSpaceBannerTitle: '',
    eventSpaceBannerDesc: '',
    eventSpaceTitle1: '',
    eventSpaceDesc1: '',
    eventSpaceTitle2: '',
    eventSpaceDesc2: '',
    accomodationTitle: '',
    accomodationDescription: '',
    nearByAddress: '',
    featuredImage: null,
    bannerImage: null,
    centerContentImage: null,
    eventSpaceBannerImage: null,
    eventSpaceLeftImage: null,
    eventSpaceRightImage: null,
    eventSpaceCenterImage: null,
    accomodationImage: null,
    nearByMapImage: null
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files : value // Set files if they exist, otherwise set value
    }));
  };

  const handleAddLocation = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('title', formData.title);
    data.append('description', formData.description);
    data.append('city', formData.city);
    data.append('state', formData.state);
    data.append('country', formData.country);
    data.append('centerContent', JSON.stringify({topText: formData?.topText, leftText: formData?.leftText, rightText: formData?.rightText }));
    data.append('bestFor', formData.bestFor);
    data.append('bestSeason', formData.bestSeason);
    data.append('eventSpaceBannerTitle', formData.eventSpaceBannerTitle);
    data.append('eventSpaceBannerDesc', formData.eventSpaceBannerDesc);
    data.append('eventSpaceTitle1', formData.eventSpaceTitle1);
    data.append('eventSpaceDesc1', formData.eventSpaceDesc1);
    data.append('eventSpaceTitle2', formData.eventSpaceTitle2);
    data.append('eventSpaceDesc2', formData.eventSpaceDesc2);
    data.append('accomodationTitle', formData.accomodationTitle);
    data.append('accomodationDescription', formData.accomodationDescription);
    data.append('nearByAddress', formData.nearByAddress);
    
    // Single file upload for featured image
    if (formData.featuredImage) {
      data.append('featuredImage', formData.featuredImage[0]);
    }

    if (formData.bannerImage) {
      data.append('bannerImage', formData.bannerImage[0]);
    }

    if (formData.centerContentImage) {
      data.append('centerContentImage', formData.centerContentImage[0]);
    }

    if (formData.eventSpaceBannerImage) {
      data.append('eventSpaceBannerImage', formData.eventSpaceBannerImage[0]);
    }

    if (formData.eventSpaceLeftImage) {
      data.append('eventSpaceLeftImage', formData.eventSpaceLeftImage[0]);
    }

    if (formData.eventSpaceRightImage) {
      data.append('eventSpaceRightImage', formData.eventSpaceRightImage[0]);
    }
    if (formData.eventSpaceCenterImage) {
      data.append('eventSpaceCenterImage', formData.eventSpaceCenterImage[0]);
    }

    if (formData.accomodationImage) {
      data.append('accomodationImage', formData.accomodationImage[0]);
    }

    if (formData.nearByMapImage) {
      data.append('nearByMapImage', formData.nearByMapImage[0]);
    }
    
    try {
      const response = await axios.post(`${config.BASE_URL}/api/admin/add-venue`, data, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        const token = localStorage.getItem('token');
        if (token) {
          navigate('/admin/venues');
        } else {
          navigate('/admin/login');
        }
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to add venue');
    }
  };

  // console.log("formData: ", formData);

  return (
    <div className="dashboard">
      <div className="container">
        <h1 className="dash_title">Dashboard</h1>
        <div className="main-section">
        <div className="back_btn_wrap">
          <button className="back-btn" onClick={() => navigate(-1)}>Go Back</button>
        </div>
          <div className="main-inner add_project_main">
            <form onSubmit={handleAddLocation} encType="multipart/form-data">
              <div className="form-login">
                <div className="add_field titleField">
                  <h2>Venue Title</h2>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={(e) => handleInputChange(e.target)}
                    required
                  />
                </div>

                <div className="add_field textareaField">
                  <h2>Description</h2>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={(e) => handleInputChange(e.target)}
                    required
                  />
                </div>

                <div className="add_field">
                  <h2>City</h2>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={(e) => handleInputChange(e.target)}
                    required
                  />
                </div>

                <div className="add_field">
                  <h2>State</h2>
                  <input
                    type="text"
                    name="state"
                    value={formData.state}
                    onChange={(e) => handleInputChange(e.target)}
                    required
                  />
                </div>

                <div className="add_field">
                  <h2>Country</h2>
                  <input
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={(e) => handleInputChange(e.target)}
                    required
                  />
                </div>

                <div className="add_field">
                  <h2>Featured Image</h2>
                  <div className="form-group">
                    <div className="file-upload">
                      <input
                        type="file"
                        className="form-control"
                        name="featuredImage"
                        onChange={(e) => handleInputChange(e.target)}
                        required
                      />
                      <i className="fa fa-camera i-pic-upload"></i>
                    </div>
                  </div>
                </div>

                <div className="add_field">
                  <h2>Banner Image</h2>
                  <div className="form-group">
                    <div className="file-upload">
                      <input
                        type="file"
                        className="form-control"
                        name="bannerImage"
                        onChange={(e) => handleInputChange(e.target)}
                        required
                      />
                      <i className="fa fa-camera i-pic-upload"></i>
                    </div>
                  </div>
                </div>

                  <div className="add_field textareaField">
                    <h3>Center Content</h3>
                  </div>

                  <div className="add_field centerContentFields">
                    <h2>Image</h2>
                    <div className="form-group">
                      <div className="file-upload">
                        <input
                          type="file"
                          className="form-control"
                          name="centerContentImage"
                          onChange={(e) => handleInputChange(e.target)}
                          required
                        />
                        <i className="fa fa-camera i-pic-upload"></i>
                      </div>
                    </div>

                    <h2>Top Text</h2>
                    <input
                      type="text"
                      name="topText"
                      value={formData.topText}
                      onChange={(e) => handleInputChange(e.target)}
                      required
                    />

                    <h2>Left Text</h2>
                    <input
                      type="text"
                      name="leftText"
                      value={formData.leftText}
                      onChange={(e) => handleInputChange(e.target)}
                      required
                    />
                  </div>

                  <div className="add_field centerContentFields">
                    <h2>Right Text</h2>
                    <input
                      type="text"
                      name="rightText"
                      value={formData.rightText}
                      onChange={(e) => handleInputChange(e.target)}
                      required
                    />

                    <h2>Best For</h2>
                    <input
                      type="text"
                      name="bestFor"
                      value={formData.bestFor}
                      onChange={(e) => handleInputChange(e.target)}
                      required
                    />

                    <h2>Best Season</h2>
                      <input
                        type="text"
                        name="bestSeason"
                        value={formData.bestSeason}
                        onChange={(e) => handleInputChange(e.target)}
                        required
                      />
                  </div>

                  <div className="bottomTabsContent">
                      <div className="topHeadings">
                        <h3>Event Spaces</h3>
                        <h4>Banner Section</h4>
                      </div>
                      <div className="content">
                        <div className="add_field 50">
                          <h2>Banner Image</h2>
                          <div className="form-group">
                            <div className="file-upload">
                              <input
                                type="file"
                                className="form-control"
                                name="eventSpaceBannerImage"
                                onChange={(e) => handleInputChange(e.target)}
                                required
                              />
                              <i className="fa fa-camera i-pic-upload"></i>
                            </div>
                          </div>
                        </div>

                        <div className="add_field 50">
                          <h2>Banner Title</h2>
                            <input
                              type="text"
                              name="eventSpaceBannerTitle"
                              value={formData.eventSpaceBannerTitle}
                              onChange={(e) => handleInputChange(e.target)}
                              required
                            />
                        </div>

                        <div className="add_field textareaField">
                          <h2>Banner Description</h2>
                            <textarea
                              name="eventSpaceBannerDesc"
                              value={formData.eventSpaceBannerDesc}
                              onChange={(e) => handleInputChange(e.target)}
                              required
                            />
                        </div>
                      </div>
                  </div>


                  <div className="bottomTabsContent">
                      <div className="topHeadings">
                        <h4>Two Images Content</h4>
                      </div>
                      <div className="content">
                        <div className="add_field">
                          <h2>Title</h2>
                            <input
                              type="text"
                              name="eventSpaceTitle1"
                              value={formData.eventSpaceTitle1}
                              onChange={(e) => handleInputChange(e.target)}
                              required
                            />
                        </div>

                        <div className="add_field">
                          <h2>Left Image</h2>
                          <div className="form-group">
                            <div className="file-upload">
                              <input
                                type="file"
                                className="form-control"
                                name="eventSpaceLeftImage"
                                onChange={(e) => handleInputChange(e.target)}
                                required
                              />
                              <i className="fa fa-camera i-pic-upload"></i>
                            </div>
                          </div>
                        </div>

                        <div className="add_field">
                          <h2>Right Image</h2>
                          <div className="form-group">
                            <div className="file-upload">
                              <input
                                type="file"
                                className="form-control"
                                name="eventSpaceRightImage"
                                onChange={(e) => handleInputChange(e.target)}
                                required
                              />
                              <i className="fa fa-camera i-pic-upload"></i>
                            </div>
                          </div>
                        </div>

                        <div className="add_field textareaField">
                          <h2>Description</h2>
                            <textarea
                              name="eventSpaceDesc1"
                              value={formData.eventSpaceDesc1}
                              onChange={(e) => handleInputChange(e.target)}
                              required
                            />
                        </div>
                      </div>
                  </div>


                  <div className="bottomTabsContent">
                      <div className="topHeadings">
                        <h4>Single Image Content</h4>
                      </div>
                      <div className="content">
                        <div className="add_field 50">
                          <h2>Title</h2>
                            <input
                              type="text"
                              name="eventSpaceTitle2"
                              value={formData.eventSpaceTitle2}
                              onChange={(e) => handleInputChange(e.target)}
                              required
                            />
                        </div>

                        <div className="add_field 50">
                          <h2>Image</h2>
                          <div className="form-group">
                            <div className="file-upload">
                              <input
                                type="file"
                                className="form-control"
                                name="eventSpaceCenterImage"
                                onChange={(e) => handleInputChange(e.target)}
                                required
                              />
                              <i className="fa fa-camera i-pic-upload"></i>
                            </div>
                          </div>
                        </div>

                        <div className="add_field textareaField">
                          <h2>Description</h2>
                            <textarea
                              name="eventSpaceDesc2"
                              value={formData.eventSpaceDesc2}
                              onChange={(e) => handleInputChange(e.target)}
                              required
                            />
                        </div>
                      </div>
                  </div>

                  <div className="bottomTabsContent">
                      <div className="topHeadings">
                        <h3>Accomodation</h3>
                      </div>
                      <div className="content">
                        <div className="add_field 50">
                          <h2>Banner Image</h2>
                          <div className="form-group">
                            <div className="file-upload">
                              <input
                                type="file"
                                className="form-control"
                                name="accomodationImage"
                                onChange={(e) => handleInputChange(e.target)}
                                required
                              />
                              <i className="fa fa-camera i-pic-upload"></i>
                            </div>
                          </div>
                        </div>

                        <div className="add_field 50">
                          <h2>Banner Description</h2>
                            <input
                              type="text"
                              name="accomodationDescription"
                              value={formData.accomodationDescription}
                              onChange={(e) => handleInputChange(e.target)}
                              required
                            />
                        </div>
                      </div>
                  </div>


                  <div className="bottomTabsContent">
                      <div className="topHeadings">
                        <h3>Near By</h3>
                      </div>
                      <div className="content">
                        <div className="add_field 50">
                          <h2>Map Image</h2>
                          <div className="form-group">
                            <div className="file-upload">
                              <input
                                type="file"
                                className="form-control"
                                name="nearByMapImage"
                                onChange={(e) => handleInputChange(e.target)}
                                required
                              />
                              <i className="fa fa-camera i-pic-upload"></i>
                            </div>
                          </div>
                        </div>

                        <div className="add_field 50">
                          <h2>Full Address</h2>
                            <input
                              type="text"
                              name="nearByAddress"
                              value={formData.nearByAddress}
                              onChange={(e) => handleInputChange(e.target)}
                              required
                            />
                        </div>
                      </div>
                  </div>

              </div>
              <div className="add_project">
                <button type="submit">Add Venue</button>
              </div>
            </form>
            {error && <p className="error-message">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEvent;
