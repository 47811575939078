import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import config from '../../config';
import { Calendar } from 'primereact/calendar';

function UpdateVenueDetail() {
    const { id } = useParams(); // Get the project ID from the URL
    const navigate = useNavigate();
    const [venue, setVenue] = useState({
        title: '',
        description: '',
        city: '',
        state: '',
        country: '',
        centerContent: '',
        bestFor: '',
        bestSeason: '',
        eventSpaceBannerTitle: '',
        eventSpaceBannerDesc: '',
        eventSpaceTitle1: '',
        eventSpaceDesc1: '',
        eventSpaceTitle2: '',
        eventSpaceDesc2: '',
        accomodationTitle: '',
        accomodationDescription: '',
        nearByAddress: '',
        featuredImage: null,
        bannerImage: null,
        centerContentImage: null,
        eventSpaceBannerImage: null,
        eventSpaceLeftImage: null,
        eventSpaceRightImage: null,
        eventSpaceCenterImage: null,
        accomodationImage: null,
        nearByMapImage: null
    });

    const [featuredImage, setFeaturedImage] = useState(null);

    const [removeImages, setRemoveImages] = useState([]);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const featuredImageRef = useRef(null);

    useEffect(() => {
        const fetchVenueDetails = async () => {
            try {
                const response = await axios.get(`${config.BASE_URL}/api/admin/get-venue/${id}`, { withCredentials: true });
                setVenue(response.data); // Set the fetched project details
            } catch (err) {
                setError('Failed to fetch venue details');
            }
        };

        fetchVenueDetails();
    }, [id]);

    const handleRemoveImage = async (imageName) => {
        try {
            const response = await axios.post(`${config.BASE_URL}/api/admin/remove-venue-image`, {
                venueId: venue._id,
                imageName
            }, { withCredentials: true });

            if (response.status === 200) {
                // Remove image from the project state and track it for removal
                setRemoveImages(prev => [...prev, imageName]); // Add to removeImages array

                // Update project state
                setVenue(prevVenue => {
                    return {
                        ...prevVenue,
                        featuredImage: prevVenue.featuredImage === imageName ? null : prevVenue.featuredImage,
                        bannerImage: prevVenue.bannerImage === imageName ? null : prevVenue.bannerImage,
                        centerContentImage: prevVenue.centerContentImage === imageName ? null : prevVenue.centerContentImage,
                        eventSpaceBannerImage: prevVenue.eventSpaceBannerImage === imageName ? null : prevVenue.eventSpaceBannerImage,
                        eventSpaceLeftImage: prevVenue.eventSpaceLeftImage === imageName ? null : prevVenue.eventSpaceLeftImage,
                        eventSpaceRightImage: prevVenue.eventSpaceRightImage === imageName ? null : prevVenue.eventSpaceRightImage,
                        eventSpaceCenterImage: prevVenue.eventSpaceCenterImage === imageName ? null : prevVenue.eventSpaceCenterImage,
                        accomodationImage: prevVenue.accomodationImage === imageName ? null : prevVenue.accomodationImage,
                        nearByMapImage: prevVenue.nearByMapImage === imageName ? null : prevVenue.nearByMapImage,
                    };
                });
            } else {
                console.error('Failed to remove image');
            }
        } catch (err) {
            console.error('Error removing image:', err);
        }
    };

    const handleFeaturedImageChange = (e) => {
        setFeaturedImage(e.target.files[0]); // Set the featured image
    };

    const handleInputChange = (e) => {
        const { name, value, files } = e;
        setVenue((prevData) => ({
          ...prevData,
          [name]: files ? files : value
        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('title', venue.title);
        formData.append('description', venue.description);
        formData.append('city', venue.city);
        formData.append('state', venue.state);
        formData.append('country', venue.country);
        formData.append('centerContent', venue.centerContent);
        formData.append('bestFor', venue.bestFor);
        formData.append('bestSeason', venue.bestSeason);
        formData.append('eventSpaceBannerTitle', venue.eventSpaceBannerTitle);
        formData.append('eventSpaceBannerDesc', venue.eventSpaceBannerDesc);
        formData.append('eventSpaceTitle1', venue.eventSpaceTitle1);
        formData.append('eventSpaceDesc1', venue.eventSpaceDesc1);
        formData.append('eventSpaceTitle2', venue.eventSpaceTitle2);
        formData.append('eventSpaceDesc2', venue.eventSpaceDesc2);
        formData.append('accomodationTitle', venue.accomodationTitle);
        formData.append('accomodationDescription', venue.accomodationDescription);
        formData.append('nearByAddress', venue.nearByAddress);

        // Add the featured image if selected
        if (venue.featuredImage) {
            formData.append('featuredImage', venue.featuredImage[0]);
        }

        if (venue.bannerImage) {
            formData.append('bannerImage', venue.bannerImage[0]);
        }

        if (venue.centerContentImage) {
            formData.append('centerContentImage', venue.centerContentImage[0]);
        }

        if (venue.eventSpaceBannerImage) {
            formData.append('eventSpaceBannerImage', venue.eventSpaceBannerImage[0]);
        }

        if (venue.eventSpaceLeftImage) {
            formData.append('eventSpaceLeftImage', venue.eventSpaceLeftImage[0]);
        }

        if (venue.eventSpaceRightImage) {
            formData.append('eventSpaceRightImage', venue.eventSpaceRightImage[0]);
        }
        if (venue.eventSpaceCenterImage) {
            formData.append('eventSpaceCenterImage', venue.eventSpaceCenterImage[0]);
        }

        if (venue.accomodationImage) {
            formData.append('accomodationImage', venue.accomodationImage[0]);
        }

        if (venue.nearByMapImage) {
            formData.append('nearByMapImage', venue.nearByMapImage[0]);
        }

        try {
            const response = await axios.put(`${config.BASE_URL}/api/admin/update-venue/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            });

            setVenue(response.data.venue);
            setSuccessMessage('Venue updated successfully!');
        } catch (err) {
            setError('Failed to update venue');
        }
    };

    return (
        <div className="dashboard update_detail_wrap">
            <div className="container">
                <h1 className="dash_title">Edit Venue</h1>
                <div className="back_btn_wrap">
                    <button className="back-btn" onClick={() => navigate(-1)}>Go Back</button>
                </div>
                {venue ? (
                    <form onSubmit={handleFormSubmit} encType="multipart/form-data">
                        <div className="update_details_wrap">
                            <div className="form-group">
                                <label>Venue Title</label>
                                <input
                                    type="text"
                                    value={venue.title}
                                    onChange={(e) => setVenue({ ...venue, title: e.target.value })}
                                />
                            </div>

                            <div className="form-group textareaField">
                                <label>Description</label>
                                <textarea
                                    name="description"
                                    value={venue.description}
                                    onChange={(e) => setVenue({ ...venue, description: e.target.value })}
                                    required
                                />
                            </div>

                            <div className="add_field form-group">
                                <label>City</label>
                                <input
                                    type="text"
                                    name="city"
                                    value={venue.city}
                                    onChange={(e) => setVenue({ ...venue, city: e.target.value })}
                                    required
                                />
                            </div>

                            <div className="add_field form-group">
                                <label>State</label>
                                <input
                                    type="text"
                                    name="state"
                                    value={venue.state}
                                    onChange={(e) => setVenue({ ...venue, state: e.target.value })}
                                    required
                                />
                            </div>

                            <div className="add_field form-group">
                                <label>Country</label>
                                <input
                                    type="text"
                                    name="country"
                                    value={venue.country}
                                    onChange={(e) => setVenue({ ...venue, country: e.target.value })}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        <div className="upload_item">
                                            <label>Featured Image</label>
                                            <input type="file" onChange={handleFeaturedImageChange} ref={featuredImageRef} />
                                        </div>

                                        <div className="detail_img_grid">
                                            {venue.featuredImage ? (
                                                <div className="detail_img_item">
                                                    <div className="banner_img_wrap">
                                                        <img src={`${config.BASE_URL}${venue.featuredImage}`} alt="Featured" />
                                                    </div>
                                                    <button className="remove_btn" type="button" onClick={() => handleRemoveImage(venue.featuredImage)}>Remove</button>
                                                </div>
                                            ) : (
                                                <p>No image available.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        <div className="upload_item">
                                            <label>Banner Image</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                name="bannerImage"
                                                onChange={(e) => handleInputChange(e.target)}
                                            />
                                        </div>

                                        <div className="detail_img_grid">
                                            {venue.bannerImage ? (
                                                <div className="detail_img_item">
                                                    <div className="banner_img_wrap">
                                                        <img src={`${config.BASE_URL}${venue.bannerImage}`} alt="Featured" />
                                                    </div>
                                                    <button className="remove_btn" type="button" onClick={() => handleRemoveImage(venue.bannerImage)}>Remove</button>
                                                </div>
                                            ) : (
                                                <p>No image available.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="add_field textareaField">
                                <h3>Center Content</h3>
                            </div>

                            <div className="add_field form-group">
                                <div className="form-group">
                                    <div className="detail_banner_wrap">
                                        <div className="detail_img_grid">
                                            <div className="upload_item">
                                                <label>Image</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    name="centerContentImage"
                                                    onChange={(e) => handleInputChange(e.target)}
                                                />
                                            </div>

                                            <div className="detail_img_grid">
                                                {venue.centerContentImage ? (
                                                    <div className="detail_img_item">
                                                        <div className="banner_img_wrap">
                                                            <img src={`${config.BASE_URL}${venue.centerContentImage}`} alt="Featured" />
                                                        </div>
                                                        <button className="remove_btn" type="button" onClick={() => handleRemoveImage(venue.centerContentImage)}>Remove</button>
                                                    </div>
                                                ) : (
                                                    <p>No image available.</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="add_field form-group udpateSection">
                                <div>
                                    <label>Top Text</label>
                                    <input
                                        type="text"
                                        name="topText"
                                        value={venue?.centerContent && JSON.parse(venue?.centerContent)?.topText}
                                        onChange={(e) => setVenue({ ...venue, centerContent: JSON.stringify({topText: e.target.value, leftText: venue?.centerContent && JSON.parse(venue?.centerContent)?.leftText, rightText: venue?.centerContent && JSON.parse(venue?.centerContent)?.rightText })})}
                                        required
                                    />

                                    <label>Left Text</label>
                                    <input
                                        type="text"
                                        name="leftText"
                                        value={venue?.centerContent && JSON.parse(venue?.centerContent)?.leftText}
                                        onChange={(e) => setVenue({ ...venue, centerContent: JSON.stringify({topText: venue?.centerContent && JSON.parse(venue?.centerContent)?.topText, leftText: e.target.value, rightText: venue?.centerContent && JSON.parse(venue?.centerContent)?.rightText })})}
                                        required
                                    />
                                </div>

                                <div>
                                    <label>Right Text</label>
                                    <input
                                        type="text"
                                        name="rightText"
                                        value={venue?.centerContent && JSON.parse(venue?.centerContent)?.rightText}
                                        onChange={(e) => setVenue({ ...venue, centerContent: JSON.stringify({topText: venue?.centerContent && JSON.parse(venue?.centerContent)?.topText, leftText: venue?.centerContent && JSON.parse(venue?.centerContent)?.leftText, rightText: e.target.value })})}
                                        required
                                    />

                                    <label>Best For</label>
                                    <input
                                        type="text"
                                        name="bestFor"
                                        value={venue.bestFor}
                                        onChange={(e) => setVenue({ ...venue, bestFor: e.target.value })}
                                        required
                                    />

                                    <label>Best Season</label>
                                    <input
                                        type="text"
                                        name="bestSeason"
                                        value={venue.bestSeason}
                                        onChange={(e) => setVenue({ ...venue, bestSeason: e.target.value })}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="bottomTabsContent form-group">
                                <div className="topHeadings">
                                    <h3>Event Spaces</h3>
                                    <label>Banner Section</label>
                                </div>
                                <div className="content form-group">
                                    <div className="add_field 50">
                                        <div className="form-group">
                                            <div className="detail_banner_wrap">
                                                <div className="detail_img_grid">
                                                    <div className="upload_item">
                                                        <label>Banner Image</label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            name="eventSpaceBannerImage"
                                                            onChange={(e) => handleInputChange(e.target)}
                                                        />
                                                    </div>

                                                    <div className="detail_img_grid">
                                                        {venue.eventSpaceBannerImage ? (
                                                            <div className="detail_img_item">
                                                                <div className="banner_img_wrap">
                                                                    <img src={`${config.BASE_URL}${venue.eventSpaceBannerImage}`} alt="Featured" />
                                                                </div>
                                                                <button className="remove_btn" type="button" onClick={() => handleRemoveImage(venue.eventSpaceBannerImage)}>Remove</button>
                                                            </div>
                                                        ) : (
                                                            <p>No image available.</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="add_field 50">
                                        <label>Banner Title</label>
                                        <input
                                            type="text"
                                            name="eventSpaceBannerTitle"
                                            value={venue.eventSpaceBannerTitle}
                                            onChange={(e) => setVenue({ ...venue, eventSpaceBannerTitle: e.target.value })}
                                            required
                                        />
                                    </div>

                                    <div className="add_field textareaField">
                                        <label>Banner Description</label>
                                        <textarea
                                            name="eventSpaceBannerDesc"
                                            value={venue.eventSpaceBannerDesc}
                                            onChange={(e) => setVenue({ ...venue, eventSpaceBannerDesc: e.target.value })}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="bottomTabsContent form-group">
                                <div className="topHeadings">
                                    <label>Two Images Content</label>
                                </div>
                                <div className="content">
                                    <div className="add_field">
                                        <label>Title</label>
                                        <input
                                            type="text"
                                            name="eventSpaceTitle1"
                                            value={venue.eventSpaceTitle1}
                                            onChange={(e) => setVenue({ ...venue, eventSpaceTitle1: e.target.value })}
                                            required
                                        />
                                    </div>

                                    <div className="add_field">
                                        <label>Left Image</label>
                                        <div className="form-group">
                                            <div className="detail_banner_wrap">
                                                <div className="detail_img_grid">
                                                    <div className="upload_item">
                                                        <label>Left Image</label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            name="eventSpaceLeftImage"
                                                            onChange={(e) => handleInputChange(e.target)}
                                                        />
                                                    </div>

                                                    <div className="detail_img_grid">
                                                        {venue.eventSpaceLeftImage ? (
                                                            <div className="detail_img_item">
                                                                <div className="banner_img_wrap">
                                                                    <img src={`${config.BASE_URL}${venue.eventSpaceLeftImage}`} alt="Featured" />
                                                                </div>
                                                                <button className="remove_btn" type="button" onClick={() => handleRemoveImage(venue.eventSpaceLeftImage)}>Remove</button>
                                                            </div>
                                                        ) : (
                                                            <p>No image available.</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="add_field">
                                        <div className="form-group">
                                            <div className="detail_banner_wrap">
                                                <div className="detail_img_grid">
                                                    <div className="upload_item">
                                                        <label>Right Image</label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            name="eventSpaceRightImage"
                                                            onChange={(e) => handleInputChange(e.target)}
                                                        />
                                                    </div>

                                                    <div className="detail_img_grid">
                                                        {venue.eventSpaceRightImage ? (
                                                            <div className="detail_img_item">
                                                                <div className="banner_img_wrap">
                                                                    <img src={`${config.BASE_URL}${venue.eventSpaceRightImage}`} alt="Featured" />
                                                                </div>
                                                                <button className="remove_btn" type="button" onClick={() => handleRemoveImage(venue.eventSpaceRightImage)}>Remove</button>
                                                            </div>
                                                        ) : (
                                                            <p>No image available.</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="add_field textareaField">
                                        <label>Description</label>
                                        <textarea
                                            name="eventSpaceDesc1"
                                            value={venue.eventSpaceDesc1}
                                            onChange={(e) => setVenue({ ...venue, eventSpaceDesc1: e.target.value })}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="bottomTabsContent form-group">
                                <div className="topHeadings">
                                    <label>Single Image Content</label>
                                </div>
                                <div className="content">
                                    <div className="add_field 50">
                                        <label>Title</label>
                                        <input
                                            type="text"
                                            name="eventSpaceTitle2"
                                            value={venue.eventSpaceTitle2}
                                            onChange={(e) => setVenue({ ...venue, eventSpaceTitle2: e.target.value })}
                                            required
                                        />
                                    </div>

                                    <div className="add_field 50">
                                        <div className="form-group">
                                            <div className="detail_banner_wrap">
                                                <div className="detail_img_grid">
                                                    <div className="upload_item">
                                                        <label>Image</label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            name="eventSpaceCenterImage"
                                                            onChange={(e) => handleInputChange(e.target)}
                                                        />
                                                    </div>

                                                    <div className="detail_img_grid">
                                                        {venue.eventSpaceCenterImage ? (
                                                            <div className="detail_img_item">
                                                                <div className="banner_img_wrap">
                                                                    <img src={`${config.BASE_URL}${venue.eventSpaceCenterImage}`} alt="Featured" />
                                                                </div>
                                                                <button className="remove_btn" type="button" onClick={() => handleRemoveImage(venue.eventSpaceCenterImage)}>Remove</button>
                                                            </div>
                                                        ) : (
                                                            <p>No image available.</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="add_field form-group textareaField">
                                        <label>Description</label>
                                        <textarea
                                            name="eventSpaceDesc2"
                                            value={venue.eventSpaceDesc2}
                                            onChange={(e) => setVenue({ ...venue, eventSpaceDesc2: e.target.value })}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="bottomTabsContent form-group">
                                <div className="topHeadings">
                                    <h3>Accomodation</h3>
                                </div>
                                <div className="content">
                                    <div className="add_field 50">
                                        <div className="form-group">
                                            <div className="detail_banner_wrap">
                                                <div className="detail_img_grid">
                                                    <div className="upload_item">
                                                        <label>Banner Image</label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            name="accomodationImage"
                                                            onChange={(e) => handleInputChange(e.target)}
                                                        />
                                                    </div>

                                                    <div className="detail_img_grid">
                                                        {venue.accomodationImage ? (
                                                            <div className="detail_img_item">
                                                                <div className="banner_img_wrap">
                                                                    <img src={`${config.BASE_URL}${venue.accomodationImage}`} alt="Featured" />
                                                                </div>
                                                                <button className="remove_btn" type="button" onClick={() => handleRemoveImage(venue.accomodationImage)}>Remove</button>
                                                            </div>
                                                        ) : (
                                                            <p>No image available.</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="add_field 50">
                                        <label>Banner Description</label>
                                        <input
                                            type="text"
                                            name="accomodationDescription"
                                            value={venue.accomodationDescription}
                                            onChange={(e) => setVenue({ ...venue, accomodationDescription: e.target.value })}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="bottomTabsContent form-group">
                                <div className="topHeadings">
                                    <h3>Near By</h3>
                                </div>
                                <div className="content">
                                    <div className="add_field 50">
                                        <div className="form-group">
                                            <div className="detail_banner_wrap">
                                                <div className="detail_img_grid">
                                                    <div className="upload_item">
                                                        <label>Banner Image</label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            name="nearByMapImage"
                                                            onChange={(e) => handleInputChange(e.target)}
                                                        />
                                                    </div>

                                                    <div className="detail_img_grid">
                                                        {venue.nearByMapImage ? (
                                                            <div className="detail_img_item">
                                                                <div className="banner_img_wrap">
                                                                    <img src={`${config.BASE_URL}${venue.nearByMapImage}`} alt="Featured" />
                                                                </div>
                                                                <button className="remove_btn" type="button" onClick={() => handleRemoveImage(venue.nearByMapImage)}>Remove</button>
                                                            </div>
                                                        ) : (
                                                            <p>No image available.</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="add_field 50">
                                        <label>Full Address</label>
                                        <input
                                            type="text"
                                            name="nearByAddress"
                                            value={venue.nearByAddress}
                                            onChange={(e) => setVenue({ ...venue, nearByAddress: e.target.value })}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="update_btn_wrap">
                                <button className="update_btn" type="submit">Update Venue</button>
                            </div>

                            {successMessage && <p className="success-message">{successMessage}</p>}

                            {error && <p className="error-message">{error}</p>}

                        </div>
                    </form>
                ) : (
                    <h5>Venue not found!</h5>
                )}
            </div>
        </div>
    );
}

export default UpdateVenueDetail;
