import React, {useEffect} from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";


const Layout = ({ children, extraClass="" }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Header />
        <div className={`site-content ${extraClass}`}>
          {children}
        </div>
      <Footer />
    </>
  );
};

export default Layout;


