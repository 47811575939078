import React, { useState } from "react";
import { Link } from "react-router-dom";
import menuToggle from "../assets/images/menu-toggle.svg";
import MobileLogo from "../assets/images/icons/MobileLogo";

import close from "../assets/images/close.svg";
const Home = () => { 
  const [isNavOpen, setIsNavOpen] = useState(false);
  const toggleNav = () => {
    setIsNavOpen(true);
  }
  const closeNav = () => {
    if (isNavOpen) {
      setIsNavOpen(false);
    }
  };

  const [scrolled, setScrolled] = useState(false);

  useState(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (    
    <header className={`site-header position-absolute w-100 ${ scrolled ? "header-overlay" : ""}`}>
        <div className="container">
            <div className="mobile-head justify-content-between">
              <div className="left-block">
                <div className="mobile-logo"><Link to="/">{<MobileLogo />}</Link></div>
              </div>
              <div className="right-block d-flex align-items-center">
                <div className="contact-menu"><Link to="#">Contact</Link></div>
                <div className="toggle-button d-flex align-items-center" onClick={toggleNav}><img width="" height="" src={menuToggle} alt="" /></div>
              </div>
            </div>
            <nav className={`site-nav ${isNavOpen ? "open-menu" : ""}`}>
                <ul className="d-flex justify-content-center">
                    <li><Link to="#">What We Do</Link></li>
                    <li><Link to="/venues">Venues</Link></li>
                    <li><Link to="#">Portfolio</Link></li>
                    <li><Link to="#">Contact</Link></li>
                    <li><Link to="/">inspo</Link></li>
                </ul>
                <div className="close-menu position-absolute" onClick={closeNav}><img width="" height="" src={close} alt="" /></div>
            </nav>
        </div>        
    </header>
  );
};

export default Home;