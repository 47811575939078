import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "./Layout";
import venuelistImg1 from "../assets/images/venue-list-img1.jpg";
import venuelistImg2 from "../assets/images/venue-list-img2.jpg";
import venueIcon from "../assets/images/venue-icon.svg";
import accomodation from "../assets/videos/accomodation.mp4";
import venue from "../assets/images/venue-icon.svg";
import { Dropdown } from 'primereact/dropdown';
const VenueIndex = () => {    
    const [selectedCity, setSelectedCity] = useState(null);
    const [isFilterSticky, setIsFilterSticky] = useState(false);
    const [filterOffset, setFilterOffset] = useState(350);

    const location = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }       
    ];
    const getOffsetFromTop = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const rect = element.getBoundingClientRect();
            const offset = rect.top + window.pageYOffset;
            return offset;
        }
        return null;
    };
    window.onscroll = function () {
        const scrollTop = document.documentElement.scrollTop;

        if (scrollTop > filterOffset) {
            setIsFilterSticky(true);
        } else {
            setIsFilterSticky(false);
        }
    };

    useEffect(() => {
        const offset = getOffsetFromTop('venue-filter-section');
        setFilterOffset(offset);
    }, []);
    

  return (  
    <Layout>  
    <>
        <div class="venue-index template-index position-relative pt-220">            
            <div className="title-block center pb-40">
                <div className="container">
                    <h1>Venues</h1>
                </div>
            </div>
            <div className="title-with-line">
              <div className="container">
                <div className="title-with-line-wrapper"><h3 className="flawlush-font">search</h3></div>
                </div>
            </div>

            <div className={`filter-section position-sticky top-0 py-40 m-py-30 ${isFilterSticky ? "sticky" : ""}`} id="venue-filter-section">
                <div className="container">
                    <div className="filter-items d-flex justify-content-center">
                        <div className="filter-item">
                            <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={location} optionLabel="name" 
                                placeholder="Location" className="w-full md:w-14rem" />
                        </div>
                        <div className="filter-item">
                            <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={location} optionLabel="name" 
                                placeholder="Event Type" className="w-full md:w-14rem" />
                        </div>
                        <div className="filter-item">
                            <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={location} optionLabel="name" 
                                placeholder="Property Type" className="w-full md:w-14rem" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="venues-section pt-50 pb-50 m-pt-10 m-pb-0">
                <div className="container">
                    <div className="venue-listing">
                        <div className="grid-item venue-item hero-section container-hero-section d-flex justify-content-center color-light position-relative">
                            <div className="hero-banner">
                                <img width="" height="" src={venuelistImg1} alt="" />
                            </div>
                            <div className="hero-summary container-summary center position-absolute">
                                <div className="container-summary-wrapper m-auto w-100">
                                    <div className="logo-block top position-absolute"><img width="" height="" src={venueIcon} alt="" /></div>
                                    <div className="title-block">Kinross House</div>
                                    <div className="cursive-title-block organizer">loch leven</div>
                                    <div className="divider"></div>
                                    <div className="title-block location">Kinross</div>
                                    <div className="text-block">a historic 17th-century Scottish country House set against the magnificent backdrop of Loch Leven.</div>
                                </div>
                            </div>
                            <div className="text text-left cursive-title-block position-absolute">the architectural <span>jewel</span> in Scotland’s crown</div>
                        </div>
                        <div className="grid-item venue-item hero-section container-hero-section d-flex justify-content-center center color-light position-relative">
                            <div className="hero-banner">
                                <img width="" height="" src={venuelistImg2} alt="" />
                            </div>
                            <div className="hero-summary container-summary position-absolute">
                                <div className="container-summary-wrapper m-auto w-100">
                                    <div className="logo-block top position-absolute"><img width="" height="" src={venueIcon} alt="" /></div>
                                    <div className="title-block">old course hotel</div>
                                    <div className="cursive-title-block organizer">St. Andrews</div>
                                    <div className="divider"></div>
                                    <div className="title-block location">FIFE</div>
                                    <div className="text-block">a historic 17th-century Scottish country House set against the magnificent backdrop of Loch Leven.</div>
                                </div>
                            </div>
                            <div className="text text-right cursive-title-block color-dark position-absolute">golf history</div>
                        </div>
                    </div>                    
                </div>
            </div>
            <div className="venue-hero-section video-section full-screen hero-section d-flex justify-content-center bottom-30 bottom-overlay-dark position-relative">
                <div className="hero-banner playsinline">
                    <video loop={true} autoPlay={true} muted={true} playsInline={true}><source src={accomodation} type="video/mp4" /></video>
                </div>                
                <div className="hero-summary d-flex flex-column justify-content-end align-items-center center color-light position-absolute h-100">
                    <div className="top-summary center color-light position-absolute">
                        <div className="logo-block"><img width="" height="" src={venue} alt="" /></div>     
                        <div className="title-block">Kinross House</div>
                    </div>
                    <div className="title-block"><h2>Luxury Private Accommodation On Loch Leven</h2></div>                
                </div>                
            </div>
        </div>
        
    </>
    </Layout>
  );
};

export default VenueIndex;