import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";
import heroImage from "../assets/images/event-hero-banner.jpg";
import celeBrates from "../assets/images/celebrates.jpg";
import hostingKinross from "../assets/images/hosting-at-kinross.jpg";
import eventSmallimg from "../assets/images/event-small-image.jpg";
import eventLargeimg from "../assets/images/event-large-image.jpg";
import itineraryImg1 from "../assets/images/itinerary-img-1.jpg";
import itineraryImg2 from "../assets/images/itinerary-img-2.jpg";
import itineraryImg3 from "../assets/images/itinerary-img-3.jpg";
import heightlight from "../assets/images/heightlight.jpg";
import heightlight_mobile from "../assets/images/heightlight-mobile.jpg";
import galleryImg1 from "../assets/images/gallery-img-1.jpg";
import galleryImg2 from "../assets/images/gallery-img-2.jpg";
import galleryImg3 from "../assets/images/gallery-img-3.jpg";
import { Swiper, SwiperSlide } from 'swiper/react';
import venueIcon from "../assets/images/venue-icon.svg";

import Lightbox from "react-image-lightbox"; // Import Lightbox
import "react-image-lightbox/style.css";

const SingleEvent = () => {

    const [isOpen, setIsOpen] = useState(false); // Lightbox state
    const [currentImage, setCurrentImage] = useState(""); // Current image state

    const images = [galleryImg1, galleryImg2, galleryImg3]; // Array of images

    const handleImageClick = (image) => {
        setCurrentImage(image); // Set the clicked image
        setIsOpen(true); // Open the lightbox
    };

    return (
        <Layout extraClass={"single-event"}>
            <>
                <div className="hero-section grid-item events-section container-hero-section d-flex justify-content-center top-bottom-overlay position-relative">
                    <div className="hero-banner">
                        <img width="" height="" src={heroImage} alt="" />
                    </div>
                    <div className="hero-summary container-summary position-absolute center color-light">
                        <div className="title-block">a 3-DAY WEDDING WEEKEND</div>
                        <div className="divider position-relative">at</div>
                        <div className="summary-wrapper">
                            <div className="logo-block"><img width="" height="" src={venueIcon} alt="" /></div>
                            <div className="title-block location">Kinross House</div>
                            <div className="cursive-title-block organizer">loch leven</div>
                        </div>
                    </div>
                </div>

                <div className="image-overlap-sidetext-section py-180 m-pt-120 m-pb-120">
                    <div className="container">
                        <div className="container-500 m-auto">
                            <div className="title-block center m-auto pb-50">
                                <h5>Modern Moments Celebrated With Tradition</h5>
                            </div>
                            <div className="image-block position-relative">
                                <img width="" height="" src={celeBrates} alt="" />
                                <div className="text text-left cursive-title-block position-absolute">family tartan</div>
                                <div className="text text-right cursive-title-block position-absolute">thisktles onthe tables</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="title-with-line">
                    <div className="container">
                        <div className="title-with-line-wrapper"><h3 className="cursive-title-block">details</h3></div>
                    </div>
                </div>

                <div className="tab-section-scroll">
                    <ul className="list-style-none d-flex justify-content-center align-items-end">
                        <li><a href="#event-spaces" className="active">Venue</a></li>
                        <li><a href="#accomodation">Itinerary</a></li>
                        <li><a href="#near-by">Gallery</a></li>
                    </ul>
                </div>

                <div id="venue" className="hero-section full-screen d-flex justify-content-center align-items-end bottom-150 bottom-overlay-dark position-relative">
                    <div className="hero-banner">
                        <img width="" height="" src={hostingKinross} alt="" />
                    </div>
                    <div className="hero-summary center color-light position-absolute">
                        <div className="title-block"><h2 className="mb-0">A Weekend At Kinross House</h2></div>
                        <div className="text-block">a historic 17th-century Scottish country House set against the magnificent backdrop of Loch Leven.</div>
                    </div>
                </div>

                <div className="image-column-section bg-black color-light center py-100">
                    <div className="container m-px-0">
                        <div className="grid-row d-grid">
                            <div className="grid-item grid-small position-relative">
                                <img width="" height="" src={eventSmallimg} alt="" />
                                <div className="text text-right position-absolute">
                                    <h3 className="cursive-title-block text-no-wrap">a cozy estate dinner</h3>
                                </div>
                            </div>
                            <div className="grid-item grid-large">
                                <img width="" height="" src={eventLargeimg} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="richtext color-light center bg-black pb-100">
                    <div className="container">
                        <div className="richtext-block m-auto">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                    </div>
                </div>

                <div id="itinerary" className="itinerary-section color-light center bg-black pb-150 m-pb-100">
                    <div className="container m-px-0">
                        <div className="title-block pb-50 m-pb-30">
                            <h2>Itinerary</h2>
                        </div>
                        <div className="grid-row">
                            <Swiper
                                spaceBetween={25}
                                loop={true}
                                centeredSlides={true}
                                initialSlide={2}
                                slidesPerView={1.4}
                                breakpoints={{
                                    1024: {
                                        loop: false,
                                        centeredSlides: false,
                                        slidesPerView: 3,
                                        spaceBetween: 30,

                                    },
                                }}
                            >
                                <SwiperSlide>
                                    <div className="grid-item">
                                        <div className="image-block">
                                            <img width="" height="" src={itineraryImg1} alt="" />
                                        </div>
                                        <div className="title-block">
                                            <h5>Welcome Dinner</h5>
                                        </div>
                                        <div className="text-block">whiskey bar and more</div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="grid-item">
                                        <div className="image-block">
                                            <img width="" height="" src={itineraryImg2} alt="" />
                                        </div>
                                        <div className="title-block">
                                            <h5>Welcome Dinner</h5>
                                        </div>
                                        <div className="text-block">whiskey bar and more</div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="grid-item">
                                        <div className="image-block">
                                            <img width="" height="" src={itineraryImg3} alt="" />
                                        </div>
                                        <div className="title-block">
                                            <h5>Welcome Dinner</h5>
                                        </div>
                                        <div className="text-block">whiskey bar and more</div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>

                <div className="hero-section d-flex justify-content-center align-items-center position-relative">
                    <div className="hero-banner">
                        <picture>
                            <source media="(max-width:768px)" srcset={heightlight_mobile} />
                            <img width="" height="" src={heightlight} alt="" />
                        </picture>
                    </div>
                    <div className="hero-summary color-light position-absolute m-px-20">
                        <div className="title-block"><h2>Highland Highlights</h2></div>
                        <div className="text-block"><h3 className="cursive-title-block">modern production in traditional spaces</h3></div>
                    </div>
                </div>

                <div id="gallery" className="gallery-section color-light center m-py-50">
                    <div className="grid-row equal-height pb-0">
                        <Swiper
                            spaceBetween={25}
                            loop={true}
                            centeredSlides={true}
                            initialSlide={2}
                            slidesPerView={1.4}
                            breakpoints={{
                                1024: {
                                    loop: false,
                                    centeredSlides: false,
                                    slidesPerView: 3,
                                    spaceBetween: 0,
                                },
                            }}
                        >
                            {images.map((image, index) => (
                                <SwiperSlide key={index}>
                                    <div
                                        className="grid-item image-block"
                                        onClick={() => handleImageClick(image)} // Open lightbox on click
                                        style={{ cursor: "pointer" }}
                                    >
                                        <img width="" height="" src={image} alt={`Gallery image ${index + 1}`} />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>

                {/* Lightbox Component */}
                {isOpen && (
                    <Lightbox
                        mainSrc={currentImage}
                        onCloseRequest={() => setIsOpen(false)} // Close the lightbox
                    />
                )}

            </>
        </Layout>
    );
};

export default SingleEvent;