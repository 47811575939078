import React from "react";
import Layout from "./Layout";
import heroImage from "../assets/images/home-hero-banner.jpg";
import bannerLogo from "../assets/images/banner-logo.svg";
import celeBrates from "../assets/images/celebrates.jpg";
import eventSmallimg from "../assets/images/event-small-image.jpg";
import eventLargeimg from "../assets/images/event-large-image.jpg";
import icon1 from "../assets/images/icon-1.svg";
import icon2 from "../assets/images/icon-2.svg";
import icon3 from "../assets/images/icon-3.svg";
import arrowLight from "../assets/images/arrpw-light.svg";
import venueBanner from "../assets/images/venue-hero-banner.jpg";
import corpoRate from "../assets/images/corporate.jpg";
import courseHotel from "../assets/images/old-course-hotel.svg";
import courseHotelDark from "../assets/images/old-course-hotel-dark.svg";
import eventImage1 from "../assets/images/event-img-1.jpg";
import eventImage2 from "../assets/images/event-img-2.jpg";
import eventImage3 from "../assets/images/event-img-3.jpg";
import venueImage1 from "../assets/images/venue-img-1.jpg";
import venueImage2 from "../assets/images/venue-img-2.jpg";
import venueImage3 from "../assets/images/venue-img-3.jpg";
import whiskey from "../assets/videos/whiskey.mp4";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import BannerLogo from "../assets/images/icons/BannerLogo";

const Home = () => {
  return (
  <Layout>
     <div className="hero-section home-hero-section full-screen center color-light d-flex justify-content-center align-items-center bottom-overlay-dark position-relative">
        <div className="hero-banner w-100 h-100">
           <img width="" height="" src={heroImage} alt="" />
        </div>
        <div className="hero-summary position-absolute">
           <div className="image-block m-auto pb-40">{<BannerLogo />}</div>
           <div className="title-block">Contemporary Events</div>
           <div className="text-block">in the Scottish tradition</div>
        </div>
        <div className="nav-block position-absolute">
           <ul className="list-style-none">
              <li>
                 <Link to="#">
                 Private Events</Link>
              </li>
              <li>
                 <Link to="#">
                 Weddings</Link>
              </li>
              <li>
                 <Link to="#">
                 Receptions</Link>
              </li>
              <li>
                 <Link to="#">
                 And More</Link>
              </li>
           </ul>
        </div>
     </div>
     <div className="image-overlap-sidetext-section py-120">
        <div className="container">
           <div className="container-500 m-auto">
              <div className="title-block center m-auto pb-50">
                 <h5>Modern Moments Celebrated With Tradition</h5>
              </div>
              <div className="image-block position-relative">
                 <img width="" height="" src={celeBrates} alt="" />
                 <div className="text text-left cursive-title-block position-absolute">family tartan</div>
                 <div className="text text-right cursive-title-block position-absolute">thisktles onthe tables</div>
              </div>
           </div>
        </div>
     </div>
     <div className="title-with-line">
        <div className="container">
           <div className="title-with-line-wrapper">
              <h3 className="cursive-title-block">welcome</h3>
           </div>
        </div>
     </div>
     <div className="tab-section-scroll">
        <ul className="list-style-none d-flex justify-content-center">
           <li><a href="#" className="active">Services</a></li>
           <li><a href="#featured-events">Events</a></li>
           <li><a href="#featured-venues">Venues</a></li>
        </ul>
     </div>
     <div className="hero-section full-screen d-flex justify-content-center bottom-150 color-light center bottom-overlay-dark position-relative">
        <div className="hero-banner">
         <video loop={true} autoPlay={true} muted={true} playsInline={true}><source src={whiskey} type="video/mp4" /></video>
        </div>
        <div className="hero-summary position-absolute">          
           <h2>Tailored<br /> Experiences</h2>
           <p>curated by local experts.</p>
        </div>
     </div>
     <div className="richtext color-light center bg-black top-bottom-equal pt-80">
        <div className="container">
           <div className="richtext-block">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
           </div>
        </div>
     </div>
     <div className="image-column-section bg-black color-light py-100">
        <div className="container m-px-0">
           <div className="grid-row d-grid">
              <div className="grid-item grid-small center position-relative">
                 <img width="" height="" src={eventSmallimg} alt="" />
                 <div className="text text-left position-absolute">
                    <div className="cursive-title-block text-no-wrap">a cozy estate dinner</div>
                 </div>
              </div>
              <div className="grid-item grid-large position-relative">
                 <img width="" height="" src={eventLargeimg} alt="" />
                 <div className="icon-text-list d-flex no-wrap flex-column justify-content-center align-items-start position-absolute">
                    <div className="icon-text-item">
                       <div className="icon-block"><img width="" height="" src={icon1} alt="" /></div>
                       <div className="title-block">Venue Sourcing</div>
                    </div>
                    <div className="icon-text-item">
                       <div className="icon-block"><img width="" height="" src={icon2} alt="" /></div>
                       <div className="title-block">Styling</div>
                    </div>
                    <div className="icon-text-item">
                       <div className="icon-block"><img width="" height="" src={icon3} alt="" /></div>
                       <div className="title-block">Any Celebration</div>
                    </div>
                    <div className="cta-block  position-absolute">
                        <Link href="/venues">view <img width="" height="" src={arrowLight} alt="" /></Link>
                     </div>
                 </div>                 
              </div>
           </div>
        </div>
     </div>
    
     <div id="featured-events" className="featured-events events-section center pt-90">
        <div className="container m-px-0">
           <div className="title-block center pb-80">
              <h6>Featured</h6>
              <h2>Events</h2>
           </div>
            <div className="event-listing">
              <div className="grid-row">
                  <div className="grid-item event-item hero-section container-hero-section d-flex justify-content-center position-relative">
                    <div className="hero-banner"><img className="w-100" width="" height="" src={corpoRate} alt="" /></div>
                    <div className="text text-right cursive-title-block position-absolute">golf history</div>       
                    <div className="hero-summary container-summary color-light position-absolute">
                      <div className="title-block">Corporate Weekend Away</div>
                      <div className="divider position-relative"><em>at</em></div>                      
                        <div className="summary-wrapper">
                          <div className="logo-block"><img width="" height="" src={courseHotel} alt="" /></div>
                          <div className="title-block location">Old Course Hotel</div>                      
                          <div className="cursive-title-block organizer">st  Andrews</div>                    
                      </div>
                    </div> 
                  </div> 
              </div>           
            </div>
           <div className="event-items py-100">
              <div className="grid-row">
              <Swiper
                spaceBetween={20}
                loop={true}
                centeredSlides={true}              
                slidesPerView={1.4}
                initialSlide={2}
                breakpoints={{                                                
                993:{   
                loop: false,                                  
                centeredSlides: false,                 
                slidesPerView: 3,
                spaceBetween: 30,
                },
                }}
                >
                  <SwiperSlide>
                  <div className="grid-item">
                  <Link to="/single-event">
                    <div className="image-block"><img className="w-100" width="" height="" src={eventImage1} alt="" /></div>
                    <div className="title-block">Corporate Weekend Away</div>
                    <div className="divider position-relative"><em>at</em></div>                  
                    <div className="logo-block"><img width="" height="" src={courseHotelDark} alt="" /></div>
                    <div className="title-block location">Old Course Hotel</div>
                    <div className="cursive-title-block organizer">st  Andrews</div>                  
                    </Link>
                  </div>
                  </SwiperSlide>
                  <SwiperSlide>
                  <div className="grid-item">
                  <Link to="/single-event">
                    <div className="image-block"><img className="w-100" width="" height="" src={eventImage2} alt="" /></div>
                    <div className="title-block">Corporate Weekend Away</div>
                    <div className="divider position-relative"><em>at</em></div>                  
                    <div className="logo-block"><img width="" height="" src={courseHotelDark} alt="" /></div>
                    <div className="title-block location">Old Course Hotel</div>
                    <div className="cursive-title-block organizer">st  Andrews</div>                  
                    </Link>
                  </div>
                  </SwiperSlide>
                  <SwiperSlide>
                  <div className="grid-item">
                  <Link to="/single-event">
                    <div className="image-block"><img className="w-100" width="" height="" src={eventImage3} alt="" /></div>
                    <div className="title-block">Corporate Weekend Away</div>
                    <div className="divider position-relative"><em>at</em></div>
                    <div className="logo-block"><img width="" height="" src={courseHotelDark} alt="" /></div>
                    <div className="title-block location">Old Course Hotel</div>
                    <div className="cursive-title-block organizer">st  Andrews</div>                  
                    </Link>
                  </div>
                  </SwiperSlide>
                </Swiper>
              </div>
           </div>
        </div>
     </div>
     <div id="featured-venues" className="featured-venues venues-section center">
        <div className="grid-item hero-section container-hero-section color-light d-flex justify-content-center top-bottom-overlay position-relative">
          <div className="title-block position-absolute">
            <h6>Featured</h6>
            <h2>Venues</h2>
          </div>
           <div className="hero-banner">
              <img width="" height="" src={venueBanner} alt="" />
           </div>
           <div className="hero-summary container-summary position-absolute center">
              <div className="container-summary-wrapper m-auto w-100">
                 <div className="title-block">Kinross House</div>
                 <div className="cursive-title-block organizer">loch leven</div>
                 <div className="divider"></div>
                 <div className="title-block location">Kinross</div>
                 <div className="text-block">a historic 17th-century Scottish country House set against the magnificent backdrop of Loch Leven.</div>
              </div>
           </div>
        </div>
        <div className="venue-items py-100">
           <div className="container m-px-0">
              <div className="grid-row">
                <Swiper
                  
                  spaceBetween={20}
                  loop={true}
                  centeredSlides={true}                    
                  slidesPerView={1.4}
                  initialSlide={2}
                  breakpoints={{                                                
                  993:{   
                  loop: false,                                  
                  centeredSlides: false,                 
                  slidesPerView: 3,
                  spaceBetween: 30,
                  },
                  }}
                >
                <SwiperSlide>                  
                  <div className="grid-item">
                     <Link to="/single-venue">
                        <div className="image-block"><img className="w-100" width="" height="" src={venueImage1} alt="" /></div>
                        <div className="title-block">Dundas Castle</div>
                        <div className="cursive-title-block organizer">west lothia</div>
                        <div className="divider"></div>
                        <div className="title-block location">Dalmeny</div>
                      </Link>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="grid-item">
                     <Link to="/single-venue">
                        <div className="image-block"><img className="w-100" width="" height="" src={venueImage2} alt="" /></div>
                        <div className="title-block">Cluny Castle</div>
                        <div className="cursive-title-block organizer">aberdeenshire</div>
                        <div className="divider"></div>
                        <div className="title-block location">Cluny</div>
                     </Link>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="grid-item">
                     <Link to="/single-venue">
                        <div className="image-block"><img className="w-100" width="" height="" src={venueImage3} alt="" /></div>
                        <div className="title-block">Borthwick Castle</div>
                        <div className="cursive-title-block organizer">edinburgh</div>
                        <div className="divider"></div>
                        <div className="title-block location">Gorebridge</div>
                     </Link>
                  </div>
                </SwiperSlide>
                </Swiper>
                </div>
           </div>
        </div>
     </div>
  </Layout>
  );
  };

export default Home;