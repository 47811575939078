import React, { useEffect, useState } from "react";
import axios from "axios"; 
import { useParams, useNavigate, Link } from "react-router-dom";
import config from '../../config';

function EventDetails() {
    const { id } = useParams(); // Get the project ID from the URL
    const [event, setEvent] = useState(null);
    const [venue, setVenue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [menus, setMenus] = useState(0);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const fetchEventDetails = async () => {
        try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-event/${id}`, { withCredentials: true });
            setEvent(response.data);
            fetchVenueDetails(response.data?.venueId);
            
        } catch (err) {
            setError('Failed to fetch location details');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEventDetails();
    }, [id]);


    const fetchVenueDetails = async (venueId) => {
        try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-venue/${venueId}`, { withCredentials: true });
            setVenue(response.data);
            
        } catch (err) {
            setError('Failed to fetch location details');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`${config.BASE_URL}/api/admin/delete-event/${id}`, { withCredentials: true });
            // Navigate back to the project list or show a success message
            navigate('/admin/events'); 
        } catch (err) {
            setError('Failed to delete the event');
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="dashboard project_detail_wrap">
            <div className="container">
                <h1 className="dash_title">Dashboard</h1>
                <div className="back_btn_wrap">
                <button className="back-btn" onClick={() => navigate(-1)}>Go Back</button>
                </div>
                <div className="main-section">
                    <div className="projects">
                        <div className="projects-inner">
                            <div className="detail_item">
                                <h2>Event Title</h2>
                                <div className="detail_item_inner">
                                    <p>{event.title}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Venue</h2>
                                <div className="detail_item_inner">
                                    <p>{venue?.title}</p>
                                </div>
                            </div>

                            <div className="detail_item twoImages">
                            <div className="detail_item">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        {event.featuredImage ? ( // No .length because it's a string
                                            <div className="detail_img_item">
                                                <h2>Featured Image</h2>
                                                <div className="banner_img_wrap">
                                                <img src={`${config.BASE_URL}${event.featuredImage}`} alt="Featured" />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <h2>Featured Image</h2>
                                                <p>No image available.</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            
                            <div className="detail_item">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        {event.bannerImage ? ( // No .length because it's a string
                                            <div className="detail_img_item">
                                                <h2>Banner Image</h2>
                                                <div className="banner_img_wrap">
                                                <img src={`${config.BASE_URL}${event.bannerImage}`} alt="Featured" />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <h2>Banner Image</h2>
                                                <p>No image available.</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            </div>

                            <div className="detail_item">
                                <h1>Center Content</h1>
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        {event.centerContentImage ? ( // No .length because it's a string
                                            <div className="detail_img_item">
                                                <h2>Image</h2>
                                                <div className="banner_img_wrap">
                                                <img src={`${config.BASE_URL}${event.centerContentImage}`} alt="Featured" />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <h2>Image</h2>
                                                <p>No image available.</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Top Text</h2>
                                <div className="detail_item_inner">
                                    <p>{event?.centerContent?.topText}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Left Text</h2>
                                <div className="detail_item_inner">
                                    <p>{event?.centerContent?.leftText}</p>
                                </div>
                            </div>

                            <div className="detail_item">
                                <h2>Right Text</h2>
                                <div className="detail_item_inner">
                                    <p>{event?.centerContent?.rightText}</p>
                                </div>
                            </div>

                            <div className="itineraryContent">
                                <h1>Itinerary</h1>
                                {event?.itineraryContent.length > 0 && event?.itineraryContent.map((item) => (
                                    <div className="singleItinerary">
                                        <div className="detail_item">
                                            <h2>Title</h2>
                                            <div className="detail_item_inner">
                                                <p>{item.title}</p>
                                            </div>
                                        </div>

                                        <div className="detail_item">
                                            <h2>Description</h2>
                                            <div className="detail_item_inner">
                                                <p>{item.description}</p>
                                            </div>
                                        </div>

                                        <div className="detail_banner_wrap">
                                            <div className="detail_img_grid">
                                                {item.itineraryImage ? ( // No .length because it's a string
                                                    <div className="detail_img_item">
                                                        <h2>Image</h2>
                                                        <div className="banner_img_wrap">
                                                        <img src={`${config.BASE_URL}${item.itineraryImage}`} alt="Featured" />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <h2>Image</h2>
                                                        <p>No image available.</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                           
                            <div className="detail_project_btn_wrap">
                                <div className="detail_edit_del">
                                    <div className="btn_item">
                                        <button className="detail_btns" onClick={handleDelete}>Delete</button>
                                    </div>
                                    <div className="btn_item">
                                        <Link to={`/admin/update-event/${event._id}`} className="update_detail detail_btns">Edit</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EventDetails;
