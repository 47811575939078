import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import config from '../../config';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";

function UpdateSettings() {
    const navigate = useNavigate();
    const [isFullWidth, setIsFullWidth] = useState();
    const [venues, setVenues] = useState([]);

    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const fetchVenues = async () => {
        try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-venues`, { withCredentials: true });
            
            if(response.data){
                setVenues(response.data);
            }
    
        } catch (err) {
            console.log('Failed to fetch venues');
        }
    };

    const fetchFullWidthVenue = async () => {
        try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-full-width-venue`, { withCredentials: true });
            
            if(response.data){
                setIsFullWidth(response.data);
            }
    
        } catch (err) {
            console.log('Failed to fetch venues');
        }
    };

    const saveSettings = async () => {
        if(isFullWidth){
            const formData = new FormData();
            formData.append('isFullWidth', true);

            try {
                const response = await axios.put(`${config.BASE_URL}/api/admin/update-venue/${isFullWidth?._id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    withCredentials: true,
                });

                setSuccessMessage('Venue updated successfully!');
            } catch (err) {
                setError('Failed to update venue');
            }
        }
    }
    
    useEffect(() => {
          fetchVenues();
          fetchFullWidthVenue();
    }, []);

    return (
        <div className="dashboard update_detail_wrap">
            <div className="container">
                <h1 className="dash_title">Settings</h1>

                <div className="add_field textareaField">
                  <h4>Select Full width Venue</h4>
                  <Dropdown 
                    value={isFullWidth} 
                    onChange={(e) => setIsFullWidth(e.value)} 
                    options={venues} 
                    optionLabel="title" 
                    placeholder="Select Venue" 
                    className="w-full md:w-14rem" 
                  />
                </div>

                {successMessage && <p className="success-message">{successMessage}</p>}
                {error && <p className="error-message">{error}</p>}

                <br />
                <Button onClick={saveSettings}>Save</Button>

                
            </div>
        </div>
    ); 
}

export default UpdateSettings;
